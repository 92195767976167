import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Pure from './Pure'
import Shine from './Shine'
import Love from './Love'
import D from './data.json'

function App() {
  const [data, setData] = useState(null);
  const [ middleClass, setMiddleClass ] = useState("js-align-middle")
  const [ section, setSection ] = useState("")
  const showSection = (s) => {
    setMiddleClass("js-align-middle js-display-top")
    setSection(s)
    return false
  }

  useEffect(() => {
    // Replace the URL with the API endpoint you want to call
    if(process.env.NODE_ENV === "development") {
      setData(D)
    } else {
      fetch('https://buddha.luno.ro/api')
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          setData(data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
    
  }, []);


  return (
    <>
      <div id="main">
        <div className={middleClass} id="intro" style={{"display": "block", "position": "absolute", "top": "40%", "left": "50%", "marginLeft": "-160px", "marginTop": "-24.5px"}}>
        <img src="/luno-logo.png" className="logo" style={{"display": "block", "opacity": "1"}} alt="" />
          <nav style={{"display": "block"}}>
            <ul>
              <li><a href="#" onClick={() => { return showSection("pure") }} className={section === "pure" ? "active" : ""}>PURE</a></li>
              <li><a href="#" onClick={() => { return showSection("love") }} className={section === "love" ? "active" : ""}>LOVE</a></li>
              <li><a href="#" onClick={() => { return showSection("shine") }} className={section === "shine" ? "active" : ""}>SHINE</a></li>
            </ul>
          </nav>
        </div>
        {section === "pure" && <Pure data={data} />}
        {section === "love" && <Love data={data} />}
        {section === "shine" && <Shine data={data} />}            
      </div>
      <section id="copyright">
        <span>Luno © {new Date().getFullYear()}. All rights reserved. | Luno is the registered trademark of Web District.</span>
      </section>
      </>
  );
}

export default App;
