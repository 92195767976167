import React, { useEffect, useState } from 'react'
function Pure({ data }) {
    const [section, setSection] = useState()

	const calculateDistance = (input) => {
        if(input === 5) { return [5, 3, 1, 3, 5] }
        if(input === 3) { return [3, 1, 3] }
      }

	useEffect(() => {
		const boxes = document.querySelectorAll('.box');
        const distances = calculateDistance(boxes.length)
        
        boxes.forEach((element, index) => {
            const elWidth = element.offsetWidth;
            const elHeight = element.offsetHeight;
            const ratio = distances[index]
            element.style.top = '50%';
            element.style.left = `${(index + 1) * 27}%`
            element.style.marginTop = `-${elHeight / 2 * ratio}px`;
            element.style.marginLeft = `-${elWidth / 2 + 50}px`;
        })
	}, [])
    
    return (
        <div id="pure">
		<div className="box" style={{"left": "68.3333px", "top": "319.68px", "opacity": "1"}}>
			<h2>The Team</h2>
			<small>who we are</small>
			<div className={section === "team" ? "info opened" : "info closed"}>
				<span className="expander" onClick={() => { setSection('team') }}>see our story</span>
                <div dangerouslySetInnerHTML={{__html: data?.content_the_team.value}}></div>
			</div>
		</div>
		<div className="box" style={{"left": "445px", "top": "390.72px", "opacity": "1"}}>
			<h2>The Dream</h2>
			<small>what are our goals</small>
            <div className={section === "dream" ? "info opened" : "info closed"}>
                <span className="expander" onClick={() => { setSection('dream') }}>see our dreams</span>
                <div dangerouslySetInnerHTML={{__html: data?.content_the_dream.value}}></div>
            </div>
		</div>
		<div className="box" style={{"left": "821.667px", "top": "319.68px", "opacity": "1"}}>
			<h2>The Earth</h2>
			<small>what are the foundations</small>
            <div className={section === "earth" ? "info opened" : "info closed"}>
                <span className="expander" onClick={() => { setSection('earth') }}>see our gears</span>
                <div dangerouslySetInnerHTML={{__html: data?.content_the_earth.value}}></div>
            </div>
		</div>
	</div>
    )
}

export default Pure