import React, { useState, useEffect } from 'react'
import SocialIcons from './social.png'
import GateIcons from './thegate-icons.png'

function Shine({ data }) {
    const [section, setSection] = useState()
    const [slide, setSlide] = useState('phone')

    const calculateDistance = (input) => {
        if(input === 5) { return [5, 3, 1, 3, 5] }
        if(input === 3) { return [3, 1, 3] }
      }

	useEffect(() => {
		const boxes = document.querySelectorAll('.box');
        const distances = calculateDistance(boxes.length)
        
        boxes.forEach((element, index) => {
            const elWidth = element.offsetWidth;
            const elHeight = element.offsetHeight;
            const ratio = distances[index]
            element.style.top = '50%';
            element.style.left = `${(index + 1) * 27}%`
            element.style.marginTop = `-${elHeight / 2 * ratio}px`;
            element.style.marginLeft = `-${elWidth / 2 + 50}px`;
        })
	}, [data])

    return (
        <div id="shine">
            {data && (
                <>
<div className="box" style={{"left": "68.3333px", "top": "284.16px", "opacity": "1"}}>
                <h2>The Future</h2>
                <small>come with us</small>
                <div className={section === "future" ? "info opened" : "info closed"}>
                    <span className="expander" onClick={() => { setSection('future') }}>and play</span>
                    <h6>Cariere</h6>
                    <p dangerouslySetInnerHTML={{__html: data.content_the_future.value}}></p>
                </div>
            </div>
            <div className="box" style={{"left": "445px", "top": "390.72px", "opacity": "1"}}>
                <h2>The Clients</h2>
                <small>who trusted us</small>
                <div className={section === "clients" ? "info big opened" : "info big closed"}>
                    <span className="expander" onClick={() => { setSection('clients') }}>and still do</span>
                    <ul className="clientsList">
                        {data.clients.map(el => {
                            return (
                                <li key={el.name}>
                                    <img src={`https://buddha.luno.ro/uploads/${el.logo}`} alt={el.name} />
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
            <div className="box" style={{"left": "821.667px", "top": "284.16px", "opacity": "1"}}>
                <h2>The Gate</h2>
                <small>where you can</small>
                <div className={section === "gate" ? "info opened" : "info closed"}>
                    <span className="expander" onClick={() => { setSection('gate') }}>reach us</span>
                    <nav className="slides-nav">
                        <a onClick={() => { setSlide('phone') }} style={{backgroundImage: `url(${GateIcons})`}} className={slide === "phone" ? "active" : ""}></a>
                        <a onClick={() => { setSlide('map') }} style={{backgroundImage: `url(${GateIcons})`}} className={slide === "map" ? "active" : ""}></a>
                        <a onClick={() => { setSlide('contact') }} style={{backgroundImage: `url(${GateIcons})`}} className={slide === "contact" ? "active" : ""}></a>
                    </nav>
                    <div className="slides">
                        <div className={slide === "phone" ? "slide active" : "slide"}>
                            <p>
                                <strong>Office:</strong> 0040 748 688 228
                            </p>
                        </div>
                        <div className={slide === "map" ? "slide active" : "slide"}>
                            <p>
                                <strong>Address:</strong> 2B, 14 Decembrie 1989 Street,
                                1st Floor Luno Office, Iasi, Romania,
                                P.O. 700123
                            </p>
                            <iframe title="Map" id="googleMap" src="https://mapsengine.google.com/map/embed?mid=z4pgPimL5hAE.k88dIxSrDi_A" width="243" height="141" frameBorder="0" style={{"border":"0"}}></iframe>
                        </div>
                        <div className={slide === "contact" ? "slide active" : "slide"}>
                            <form action="" method="POST" id="contact">
                                <label>Who are you?</label>
                                <input type="text" name="name" />
                                <label>Who should we reply to?</label>
                                <input type="email" name="email" />
                                <label>What is there to say?</label>
                                <textarea rows="4" name="message"></textarea>
                                <input type="submit" name="send" value="send message" />
                            </form>
                        </div>
                    </div>
                </div>
                <ul className={section === "gate" ? "social active" : "social"}>
                    <li>
                        <a style={{backgroundImage: `url(${SocialIcons})`}} href="https://www.facebook.com/theLuno" className="facebook" target="_blank" rel="noreferrer">Facebook</a>
                    </li>
                    <li>
                        <a style={{backgroundImage: `url(${SocialIcons})`}} href="http://vimeo.com/luno" className="vimeo" target="_blank" rel="noreferrer">Vimeo</a>
                    </li>
                    <li>
                        <a style={{backgroundImage: `url(${SocialIcons})`}} href="https://www.youtube.com/user/TheLunoStudio" className="youtube" target="_blank" rel="noreferrer">YouTube</a>
                    </li>
                </ul>
            </div>
                </>
            )}
           
        </div>
    )
}

export default Shine;