import React, { useEffect, useState } from 'react'
import White50 from './50white.png'
import Play from './play.png'
import PortfolioNavArrows from './portfolio-nav-arrows.png'
import PortfolioPinkSplit from './portfolio-pink-split.png'

function Love({data}) {
    const [section, setSection] = useState("")
    const [ currentProject, setCurrentProject ] = useState()
    const [ projects, setProjects ] = useState([])
    
    useEffect(() => {
        if(data?.portfolio) {
            const _p = data.portfolio.filter(p => {
                return p.categories.map(c => c.slug).includes(section)
            })
            setProjects(_p)
            setCurrentProject(_p[0])
        }
    }, [section, data])

    const calculateDistance = (input) => {
        if(input === 5) { return [5, 3, 1, 3, 5] }
        if(input === 3) { return [3, 1, 3] }
      }

    useEffect(() => {
        // This code will run when the component is rendered
        const elements = document.querySelectorAll('.js-align-middle');
        
        elements.forEach(element => {
          element.style.left = '-3000px';
          element.style.display = 'block';
    
          const children = element.children;
          for (let i = 0; i < children.length; i++) {
            children[i].style.display = 'block';
          }
    
          const elWidth = element.offsetWidth;
          const elHeight = element.offsetHeight;
        //   for (let i = 0; i < children.length; i++) {
        //     children[i].style.display = 'none';
        //   }
    
          element.style.position = 'absolute';
          element.style.top = '40%';
          element.style.left = '50%';
          element.style.marginLeft = `-${elWidth / 2}px`;
          element.style.marginTop = `-${elHeight / 2}px`;
        });

        const boxes = document.querySelectorAll('.box');
        const distances = calculateDistance(boxes.length)
        
        boxes.forEach((element, index) => {
            const elWidth = element.offsetWidth;
            const elHeight = element.offsetHeight;
            const ratio = distances[index]
            element.style.top = '50%';
            element.style.left = `${(index + 1) * 19}%`
            element.style.marginTop = `-${elHeight / 2 * ratio}px`;
            element.style.marginLeft = `-${elWidth / 2 + 50}px`;
        })
      }, []);

    const getMedia = (media) => {
        const d = JSON.parse(media)
        if(d.image) {
            return {
                "image": d.image,
                "video": d.video
            }
        } else if(d.vimeoImage) {
            return {
                "image": d.vimeoImage,
                "video": d.vimeo
            }
        } else {
            return {
                "image": d.youtubeImage,
                "video": d.video
            }
        }
    }

    const prevItem = () => {
        const i = projects.map(p => p.id).indexOf(currentProject.id)
        if(i - 1 < 0) {
            setCurrentProject(projects[projects.length - 1])
        } else {
            setCurrentProject(projects[i - 1])
        }
    }

    const nextItem = () => {
        const i = projects.map(p => p.id).indexOf(currentProject.id)
        if(i + 1 < projects.length) {
            setCurrentProject(projects[i + 1])
        } else {
            setCurrentProject(projects[0])
        }
    }

    return (
        <div id="love">
            {currentProject && (
            <div id="portfolio" style={{backgroundImage: `url(${White50})`, opacity: `${section ? 1 : 0}`}}>
                <span className="key">our joy</span>
                <figure style={{"backgroundImage":`url('${getMedia(currentProject.media).image}')`}}>
                    <a href={getMedia(currentProject.media).video} className="play" target="_blank" style={{backgroundImage: `url(${Play})`}}>play</a>
                </figure>
                <nav style={{backgroundImage: `url(${PortfolioPinkSplit})`}}>
                    <a onClick={nextItem} className="right" style={{backgroundImage: `url(${PortfolioNavArrows})`}}></a>
                    <a onClick={prevItem} className="left" style={{backgroundImage: `url(${PortfolioNavArrows})`}}></a>
                </nav>
                <div className="content">
                    <h4>{currentProject.title}</h4>
                    <h6>{currentProject.subtitle}</h6>
                    <div className="inside_categories">{currentProject.categories.map(c => c.name).join(" / ")}</div><br clear="both" />
                    <div className="details" dangerouslySetInnerHTML={{__html: currentProject.description}}></div>
                </div>
                <div className="categories">
                    <a onClick={() => { setSection('show') }} className={section === "show" ? "active" : ""}>Show</a>
                    <a onClick={() => { setSection('film') }} className={section === "film" ? "active" : ""}>Film</a>
                    <a onClick={() => { setSection('branding') }} className={section === "branding" ? "active" : ""}>Branding</a>
                    <a onClick={() => { setSection('web') }} className={section === "web" ? "active" : ""}>Web</a>
                    <a onClick={() => { setSection('design') }} className={section === "design" ? "active" : ""}>Design</a>
                </div>
                <div id="shader"></div>
            </div>
            )}
        <div className="box" style={{"left": "49.5px", "opacity": `${section ? 0 : 1}`, "top": "355.2px", "transform": "translate(0px, 0px) rotate(0rad) skewX(0rad) scale(1, 1)"}}>
            <h2>Show</h2>
            <small>meditate</small>
            <div className={section === "show" ? "info closed" : "info closed"}>
                <span className="expander" onClick={() => { setSection('show') }}>live purely</span>
            </div>
        </div>
        <div className="box" style={{"left": "275.5px", "opacity": `${section ? 0 : 1}`, "top": "399.6px", "transform": "translate(0px, 0px) rotate(0rad) skewX(0rad) scale(1, 1)"}}>
            <h2>Film</h2>
            <small>do your work</small>
            <div className={section === "film" ? "info closed" : "info closed"}>
                <span className="expander" onClick={() => { setSection('film') }}>with mastery</span>
            </div>
        </div>
        <div className="box" style={{"left": "501.5px", "opacity": `${section ? 0 : 1}`, "top": "444px", "transform": "translate(0px, 0px) rotate(0rad) skewX(0rad) scale(1, 1)"}}>
            <h2>Branding</h2>
            <small>like the moon</small>
            <div className={section === "branding" ? "info closed" : "info closed"}>
                <span className="expander" onClick={() => { setSection('branding') }}>come out</span>
            </div>
        </div>
        <div className="box" style={{"left": "727.5px", "opacity": `${section ? 0 : 1}`, "top": "399.6px", "transform": "translate(0px, 0px) rotate(0rad) skewX(0rad) scale(1, 1)"}}>
            <h2>Web</h2>
            <small>from behind</small>
            <div className={section === "web" ? "info closed" : "info closed"}>
                <span className="expander" onClick={() => { setSection('web') }}>the clouds</span>
            </div>
        </div>
        <div className="box" style={{"left": "953.5px", "opacity": `${section ? 0 : 1}`, "top": "355.2px", "transform": "translate(0px, 0px) rotate(0rad) skewX(0rad) scale(1, 1)"}}>
            <h2>Design</h2>
            <small>be quiet</small>
            <div className={section === "design" ? "info closed" : "info closed"}>
                <span className="expander" onClick={() => { setSection('design') }}>shine</span>
            </div>
        </div>
    </div>
    )
}

export default Love